import { NumberRange } from '@openreel/common';
import { cloneDeep, flatten, inRange, isEqual, sortBy } from 'lodash';
import {
  getAssetDuration,
  getLayers,
  getSectionDuration,
  getSectionTypeTimelines,
  getTimelineDuration,
  getTimelinesByTypeAndSectionId,
  getWorkflowUsedAssetIds,
} from '../helpers';
import {
  LayerOptions,
  LottieLayer,
  SectionLayer,
  SectionTimeline,
  SimpleBoundsWithAngle,
  TimelineType,
  WorkflowDataDto,
} from '../interfaces';

const GAP_THRESHOLD_MS = 50;

export interface WorkflowDomainValidatorOptions {
  aspectRatio: { width: number; height: number };
}

export class WorkflowDomainValidator {
  private workflow: WorkflowDataDto;

  constructor(private readonly original: WorkflowDataDto) {
    this.workflow = cloneDeep(this.original);
  }

  validate(options: WorkflowDomainValidatorOptions) {
    this.noOverlappingVisibility()
      .noNegativeVisibilty()
      .visibilityMustBeFilled()
      .mainClipsShouldNotHaveGaps()
      .backgroundTimelinesMustHaveItems()
      .noDuplicateAssetIds()
      .noDuplicateLayerIds()
      .noTextStylesWithoutFont()
      .zIndicesMustBeSequential()
      .allSectionsMustExist()
      .sectionDurationsMustMatch()
      .groupsAreValid()
      .assetsAreValid()
      .assetsTrimsAndCutsAreValid()
      .resolutionMustMatchAspectRatio(options.aspectRatio.width, options.aspectRatio.height)
      .validateObjectFit()
      .validateTimelinePairs()
      .validateEmptyTimelines()
      .noMainVideoAssetReuses();
  }

  private noOverlappingVisibility() {
    const mainTimelines = getSectionTypeTimelines(this.workflow.sections, 'main');
    for (const mainTimeline of mainTimelines) {
      if (!this.checkTimelineForOverlapsInVisibility(mainTimeline)) {
        throw new Error('Error in workflow: visibility overlaps.');
      }
    }

    return this;
  }

  private noNegativeVisibilty() {
    const mainTimelines = getSectionTypeTimelines(this.workflow.sections, 'main');
    for (const mainTimeline of mainTimelines) {
      if (!this.checkTimelineForNegativeVisiblity(mainTimeline)) {
        throw new Error('Error in workflow: negative visibility.');
      }
    }

    return this;
  }

  private visibilityMustBeFilled() {
    const timelinesWithRequiredEndAt: TimelineType[] = ['main', 'b-roll', 'overlays'];
    for (const [sectionId, section] of Object.entries(this.workflow.sections)) {
      for (const timeline of section.timelines) {
        for (const layer of timeline.layers) {
          const shouldHaveEndAtFilled = timelinesWithRequiredEndAt.includes(timeline.type);

          if (shouldHaveEndAtFilled && typeof layer.visibility?.endAt !== 'number') {
            throw new Error(
              `Error in workflow: missing endAt. Section: ${sectionId}, timeline: ${timeline.id}, layer ID: ${layer.layerId}.`
            );
          }

          if (layer.visibility.startAt < 0 || layer.visibility.endAt > section.sectionDuration) {
            throw new Error(
              `Error in workflow: invalid startAt. Section: ${sectionId}, timeline: ${timeline.id}, layer ID: ${layer.layerId}.`
            );
          }

          if (
            shouldHaveEndAtFilled &&
            (layer.visibility.endAt < 0 || layer.visibility.endAt > section.sectionDuration)
          ) {
            throw new Error(
              `Error in workflow: invalid startAt. Section: ${sectionId}, timeline: ${timeline.id}, layer ID: ${layer.layerId}.`
            );
          }
        }
      }
    }
    return this;
  }

  private mainClipsShouldNotHaveGaps() {
    const checkTimeline = (timeline: SectionTimeline, longerTimelineDuration: number) => {
      if (timeline.layers.length === 0) {
        return true;
      }

      // Just check longer timeline since shorter one can have gaps
      const timelineDuration = getTimelineDuration(timeline, this.workflow.assets);
      if (timelineDuration !== longerTimelineDuration) {
        return true;
      }

      const sortedLayers = sortBy(timeline.layers, (layer) => layer.visibility?.startAt || 0);
      let currentPos = null;
      for (const layer of sortedLayers) {
        const { startAt, endAt } = layer.visibility;
        if (currentPos !== null && startAt - currentPos > GAP_THRESHOLD_MS) {
          return false;
        }
        currentPos = endAt;
      }

      return true;
    };

    const mainTimelines = getSectionTypeTimelines(this.workflow.sections, 'main');
    const timelinesToCheck = mainTimelines.filter((t) => t.type === 'main' || t.type === 'b-roll');

    const allTimelinesPass = timelinesToCheck.every((t) =>
      checkTimeline(t, getTimelineDuration(t, this.workflow.assets))
    );
    if (!allTimelinesPass) {
      throw new Error('Error in workflow: main clips contain gaps.');
    }

    return this;
  }

  private backgroundTimelinesMustHaveItems() {
    const bgTimelines = getTimelinesByTypeAndSectionId(this.workflow.sections, 'background');
    if (bgTimelines.some((t) => t.layers.length === 0)) {
      throw new Error('Error in workflow: background timelines without items.');
    }

    return this;
  }

  private noDuplicateAssetIds() {
    const assetIds = new Set<string>();

    for (const asset of this.workflow.assets) {
      if (assetIds.has(asset.id)) {
        throw new Error('Error in workflow: duplicate asset IDs.');
      }

      assetIds.add(asset.id);
    }

    return this;
  }

  private noDuplicateLayerIds() {
    const layerIds = new Set<string>();

    for (const { layer } of getLayers(this.workflow)) {
      if (layerIds.has(layer.layerId)) {
        throw new Error('Error in workflow: duplicate layer IDs.');
      }

      layerIds.add(layer.layerId);
    }

    return this;
  }

  private noTextStylesWithoutFont() {
    for (const { layer } of getLayers(this.workflow, { types: ['lottie'] })) {
      const lottieLayer = layer as LottieLayer & LayerOptions;
      for (const [fieldId, field] of Object.entries(lottieLayer.data)) {
        if (field.type !== 'text') {
          continue;
        }

        const style = this.workflow.styles.find((s) => s.id === field.styleId);
        if (!style) {
          throw new Error(`Missing style on lottie layer ID: ${lottieLayer.layerId}, field: ${fieldId}`);
        }

        if (!style.font) {
          throw new Error(
            `Missing font on lottie layer ID: ${lottieLayer.layerId}, field: ${fieldId}, style: ${style.id}`
          );
        }

        if (!style.fontWeight) {
          throw new Error(
            `Missing font weight on lottie layer ID: ${lottieLayer.layerId}, field: ${fieldId}, style: ${style.id}`
          );
        }
      }
    }

    return this;
  }

  private zIndicesMustBeSequential() {
    const sectionKeys = Object.keys(this.workflow.sections);
    for (const sectionKey of sectionKeys) {
      let counter = 0;
      const sortedTimelines = sortBy(this.workflow.sections[sectionKey].timelines, (timeline) => timeline.zIndex);

      for (const timeline of sortedTimelines) {
        if (timeline.zIndex !== counter) {
          throw new Error('Timeline zIndices must be sequential.');
        }
        counter++;
      }
    }

    return this;
  }

  private allSectionsMustExist() {
    const sectionIdsFromLayers = this.workflow.timelines
      .find((t) => t.type === 'main')
      .layers.map((layer) => (layer as SectionLayer).sectionId);

    const sectionIdsFromKeys = Object.keys(this.workflow.sections);

    if (!isEqual(sortBy(sectionIdsFromKeys), sortBy(sectionIdsFromLayers))) {
      throw new Error('Section IDs must be identical in layers and Sections object.');
    }

    return this;
  }

  private sectionDurationsMustMatch() {
    for (const [key, section] of Object.entries(this.workflow.sections)) {
      const mainTimelines = section.timelines.filter((t) => t.type === 'main');
      if (mainTimelines.length === 0) {
        continue;
      }

      const sectionDuration = getSectionDuration(section, this.workflow.assets);
      if (sectionDuration !== section.sectionDuration) {
        throw new Error(
          `Section ${key} has incorrect duration. Main timelines duration: ${sectionDuration}, section duration: ${section.sectionDuration}.`
        );
      }
    }

    return this;
  }

  private groupsAreValid() {
    const allCheckedLayerIds = new Set<string>();
    const groupIds = new Set<string>();

    for (const [key, section] of Object.entries(this.workflow.sections)) {
      if (!section.groups) {
        throw new Error(`Groups can not be empty: section ${key} does not have groups property.`);
      }

      const sectionLayerIds = flatten(section.timelines.map((t) => t.layers.map((layer) => layer.layerId)));

      for (const group of section.groups) {
        if (!group.groupId) {
          throw new Error(`GroupId can not be empty.`);
        }

        if (groupIds.has(group.groupId)) {
          throw new Error(`GroupIds must be unique.`);
        }
        groupIds.add(group.groupId);

        if (group.layerIds?.length ?? 0 < 2) {
          throw new Error(`Each group should contain at least two layers.`);
        }

        if (!this.checkSimpleBoundsWithAngleIsValid(group.bounds)) {
          throw new Error(`Group has invalid bounds.`);
        }

        const groupCheckedLayerIds = new Set<string>();

        for (const layerId of group.layerIds) {
          if (groupCheckedLayerIds.has(layerId)) {
            throw new Error(`Layers must be unique in a group.`);
          }

          if (sectionLayerIds.indexOf(layerId) === -1) {
            throw new Error(`Group can not contains layers outside of the section.`);
          }

          if (allCheckedLayerIds.has(layerId)) {
            throw new Error(`Layer can not be a part of more than one group.`);
          }

          groupCheckedLayerIds.add(layerId);
          allCheckedLayerIds.add(layerId);
        }
      }
    }

    return this;
  }

  private assetsAreValid() {
    const usedAssetsIds = Array.from(getWorkflowUsedAssetIds(this.workflow));
    const workflowAssetIds = this.workflow.assets.map((asset) => asset.id);

    const result = usedAssetsIds.filter((assetId) => !workflowAssetIds.includes(assetId));

    if (result.length) {
      throw new Error('Some used assetIds are not present in workflow assets.');
    }

    return this;
  }

  private assetsTrimsAndCutsAreValid() {
    function validateRange(range: NumberRange, duration: number) {
      if (!inRange(range.from, 0, (duration ?? range.from) + 1)) {
        throw new Error(`Asset 'range.from' must be between '0' and 'duration'.`);
      }
      if (!inRange(range.to, 0, (duration ?? range.to) + 1)) {
        throw new Error(`Asset 'range.to' must be between '0' and 'duration'.`);
      }
      if (range.from > range.to) {
        throw new Error(`Asset 'range.from' can not be greater than 'range.to'.`);
      }
    }

    for (const asset of this.workflow.assets) {
      if (asset.type !== 'clip') {
        continue;
      }

      const duration = getAssetDuration(asset);

      if (asset.trim) {
        validateRange(asset.trim, duration);
      }

      if (asset.textCuts) {
        for (let i = 0; i < asset.textCuts.length; i++) {
          const cut = asset.textCuts[i];
          const nextCut = i !== asset.textCuts.length - 1 ? asset.textCuts[i + 1] : null;

          validateRange(cut, duration);

          if (nextCut && cut.to > nextCut.from) {
            throw new Error(`Asset 'textCuts' must be in ascending order and can not overlap.`);
          }
        }
      }
    }

    return this;
  }

  private resolutionMustMatchAspectRatio(aspectRatioWidth: number, aspectRatioHeight: number) {
    const resolutionsMatch =
      this.workflow.globalSettings?.resolution?.width === aspectRatioWidth &&
      this.workflow.globalSettings?.resolution?.height === aspectRatioHeight;

    if (!resolutionsMatch) {
      throw new Error('Resolution in workflow must match selected aspect ratio.');
    }

    return this;
  }

  private validateObjectFit() {
    for (const { layer } of getLayers(this.workflow)) {
      if (layer.type === 'lottie' && layer.styles?.objectFit === 'cover') {
        throw new Error(`Lottie layers do not support 'cover' fit.`);
      }
      if (layer.type === 'video' && layer.styles?.objectFit === 'stretch') {
        throw new Error(`Video layers do not support 'stretch' fit.`);
      }
    }

    return this;
  }

  private validateTimelinePairs() {
    for (const [, section] of Object.entries(this.workflow.sections)) {
      if (section.sectionType !== 'main') {
        continue;
      }

      const mainTimelines = section.timelines.filter((t) => t.type === 'main');
      for (const mainTimeline of mainTimelines) {
        if (!mainTimeline.pairId) {
          continue;
        }

        const pairedTimeline = section.timelines.find((t) => t.id === mainTimeline.pairId);
        if (!pairedTimeline) {
          throw new Error(`Main timeline ${mainTimeline.id} uses unknown pair timeline ${mainTimeline.pairId}.`);
        }
      }
    }

    return this;
  }

  private validateEmptyTimelines() {
    const timelineTypesToValidate: TimelineType[] = ['text-boxes', 'images'];

    for (const [, section] of Object.entries(this.workflow.sections)) {
      for (const timeline of section.timelines.filter((t) => timelineTypesToValidate.includes(t.type))) {
        if (!timeline.layers || timeline.layers.length === 0) {
          throw new Error(`Timeline ${timeline.id} has no layers.`);
        }
      }
    }

    return this;
  }

  private noMainVideoAssetReuses() {
    const usedAssetIds = new Set<string>();
    for (const [, section] of Object.entries(this.workflow.sections)) {
      for (const timeline of section.timelines.filter((t) => t.type === 'main' && t.layers.length > 0)) {
        const layer = timeline.layers[0];
        if (layer.type !== 'video') {
          continue;
        }

        if (usedAssetIds.has(layer.assetId)) {
          throw new Error(`Asset id ${layer.assetId} is used more than once.`);
        }

        usedAssetIds.add(layer.assetId);
      }
    }

    return this;
  }

  private checkTimelineForOverlapsInVisibility(timeline: SectionTimeline) {
    if (timeline.layers.length === 0) {
      return true;
    }

    let currentPos = null;
    const sortedLayers = sortBy(timeline.layers, (layer) => layer.visibility?.startAt || 0);
    for (const layer of sortedLayers) {
      if (currentPos !== null && layer.visibility.startAt <= currentPos) {
        console.error(
          `Error on: ${layer.layerId}. Previous layer: ${currentPos}, Current Layer: ${layer.visibility.startAt}`
        );
        return false;
      }
      currentPos = layer.visibility.endAt;
    }

    return true;
  }

  private checkTimelineForNegativeVisiblity(timeline: SectionTimeline) {
    if (timeline.layers.length === 0) {
      return true;
    }

    for (const layer of timeline.layers) {
      const { startAt, endAt } = layer.visibility;
      if (startAt < 0 || endAt < 0) {
        console.error(`Error on ${layer.layerId}. ${startAt}, ${endAt}`);
        return false;
      }
    }

    return true;
  }

  private checkSimpleBoundsWithAngleIsValid(bounds: SimpleBoundsWithAngle) {
    return (
      bounds?.x != null && bounds.y != null && bounds.width != null && bounds.height != null && bounds.angleRad != null
    );
  }
}
