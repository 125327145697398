import { WorkflowBaseBuilder } from '../builders/workflow-base.builder';
import { getLayers } from '../helpers';
import { DEFAULT_MAIN_SECTION_ID, TemplateBuilderAsset, TemplateBuilderPlaceholders } from '../interfaces';
import { createSectionLayer } from '../template-builder/baseWorkflow';
import { createMainSectionFromBuilder } from '../template-builder/createMainSection';
import { createVideoAsset } from '../template-builder/createVideoAsset';

export interface UpsertPlaceholderResult {
  action: 'add' | 'remove';
  newAssetId?: string;
}

export abstract class WorkflowTemplatesBaseBuilder<T, R = null> extends WorkflowBaseBuilder<T, R> {
  protected addDefaultMainSection() {
    const mainTimeline = this.source.timelines.find((t) => t.type === 'main');

    const { section, assets, styles } = createMainSectionFromBuilder(this.source);
    this.source.sections[DEFAULT_MAIN_SECTION_ID] = section;

    const introIndex = mainTimeline.layers.findIndex(
      (layer) => layer.type === 'section' && layer.sectionId === 'intro'
    );

    if (introIndex >= 0) {
      mainTimeline.layers.splice(introIndex + 1, 0, createSectionLayer(DEFAULT_MAIN_SECTION_ID));
    } else {
      mainTimeline.layers.splice(0, 0, createSectionLayer(DEFAULT_MAIN_SECTION_ID));
    }

    this.addAndReplaceAssets(assets);
    this.addAndReplaceStyles(styles);
  }

  protected upsertPlaceholders(placeholders: TemplateBuilderPlaceholders) {
    const currentPlaceholders = this.source.globalSettings.placeholders;

    {
      const result = this.upsertPlaceholder(placeholders.simple, currentPlaceholders.simpleAssetId);
      if (result?.action === 'add') {
        currentPlaceholders.simpleAssetId = result.newAssetId;
      }
      if (result?.action === 'remove') {
        delete currentPlaceholders.simpleAssetId;
      }
    }

    {
      const result = this.upsertPlaceholder(placeholders.leftInterview, currentPlaceholders.interviewAssetIds.left);
      if (result?.action === 'add') {
        currentPlaceholders.interviewAssetIds.left = result.newAssetId;
      }
      if (result?.action === 'remove') {
        delete currentPlaceholders.interviewAssetIds.left;
      }
    }

    {
      const result = this.upsertPlaceholder(placeholders.rightInterview, currentPlaceholders.interviewAssetIds.right);
      if (result?.action === 'add') {
        currentPlaceholders.interviewAssetIds.right = result.newAssetId;
      }
      if (result?.action === 'remove') {
        delete currentPlaceholders.interviewAssetIds.right;
      }
    }

    {
      const result = this.upsertPlaceholder(
        placeholders.leftPresentation,
        currentPlaceholders.presentationAssetIds.left
      );
      if (result?.action === 'add') {
        currentPlaceholders.presentationAssetIds.left = result.newAssetId;
      }
      if (result?.action === 'remove') {
        delete currentPlaceholders.presentationAssetIds.left;
      }
    }
    {
      const result = this.upsertPlaceholder(
        placeholders.rightPresentation,
        currentPlaceholders.presentationAssetIds.right
      );
      if (result?.action === 'add') {
        currentPlaceholders.presentationAssetIds.right = result.newAssetId;
      }
      if (result?.action === 'remove') {
        delete currentPlaceholders.presentationAssetIds.right;
      }
    }
    return this.ok();
  }

  private upsertPlaceholder(formAsset: TemplateBuilderAsset, assetId: string): UpsertPlaceholderResult | null {
    // Do nothing
    if (!formAsset.assetFileId && !assetId) {
      return;
    }

    // Remove placeholder
    if (!formAsset.assetFileId && assetId) {
      this.source.assets = this.source.assets.filter((a) => a.id !== assetId);
      this.removePlaceholderLayers(assetId);
      return { action: 'remove' };
    }

    // Add placeholder
    if (formAsset.assetFileId && !assetId) {
      const asset = createVideoAsset(formAsset.assetFileId, formAsset.duration);
      this.source.assets.push({ ...asset, isGlobal: true, isPlaceholder: true });
      return { action: 'add', newAssetId: asset.id };
    }

    // Update placeholder (if changed)
    if (formAsset.assetFileId && assetId) {
      const asset = this.getAsset(assetId);
      if (formAsset.assetFileId === asset.file?.path) {
        return;
      }

      asset.file = { path: formAsset.assetFileId, provider: 'or-assets' };
      asset.data = { ...asset.data, duration: formAsset.duration };

      this.updatePlaceholderInLayers(assetId, formAsset.duration);
    }
  }

  private removePlaceholderLayers(assetId: string) {
    for (const { layer, timeline } of getLayers(this.source)) {
      if (layer.type !== 'video' || layer.assetId !== assetId) {
        continue;
      }

      timeline.layers = [];
    }
  }

  private updatePlaceholderInLayers(assetId: string, newDuration: number) {
    for (const { layer } of getLayers(this.source)) {
      if (layer.type !== 'video' || layer.assetId !== assetId) {
        continue;
      }

      layer.visibility = { startAt: 0, endAt: newDuration };
    }
  }
}
