import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { map, Observable } from 'rxjs';
import { commonenv } from '../../environments/environment';
import {
  HostableVideo,
  HostingHub,
  HostingParentObject,
  HostingVideoViewsCounter,
  UpdateVideoDetails,
  SortOption,
  HostingCaption,
  CreateHostingCaption,
  UpdateHostingCaption,
  HostingThumbnail,
  CreateHostingThumbail,
  HostableCountFilters,
  HostableListFilters,
  CreateHostingCta,
  HostingCta,
  UpdateHostingCta,
  HostingCreateData,
  HostingEmbedDto,
  HostableVideoEmbedApiResp,
  HostingVideoChapter,
  CreateHostingVideoChapterDTO,
  UpdateHostingVideoChapterDTO,
  HostingVideoTranscript,
  HostingVideoAppearance,
  UpdateHubDetails,
  HostingCaptionsTranslation,
  HubVideoListQueryDto,
} from '../hosting-interfaces';
import { HostableTypes, HostablePermissionTypes } from '../constants';
import { SelfRecordSource } from '@openreel/common';

@Injectable({ providedIn: 'root' })
export class HostingService {
  private baseUrl = commonenv.nextGenApiUrl;

  constructor(private http: HttpClient) {}

  // ----------------------- H O S T I N G   V I D E O S -----------------------

  //
  // Creates hostable video - HLS variant with JPG thumbnail and Animated PNG thumbnail
  //
  selfRecordHostVideo(
    recordingSource: SelfRecordSource,
    {
      camera,
      screen,
    }: {
      camera?: HostingCreateData;
      screen?: HostingCreateData;
    }
  ) {
    let cameraData;
    if (camera) {
      cameraData = {
        type: HostableTypes.UserUpload,
        ...camera,
      };
    }

    let screenData;
    if (screen) {
      screenData = {
        type: HostableTypes.UserUpload,
        ...screen,
      };
    }

    return this.http.post<{ camera: HostableVideo; screen: HostableVideo }>(
      `${this.baseUrl}hosting/videos/self-record`,
      {
        source: recordingSource,
        camera: cameraData,
        screen: screenData,
      }
    );
  }

  hostVideo(id: number, type: HostableTypes, createData: HostingCreateData) {
    return this.http.post<HostableVideo>(`${this.baseUrl}hosting/videos`, {
      id,
      type,
      ...createData,
    });
  }

  //
  // Update Video details
  //
  putVideo(videoId: number, videoDetails: UpdateVideoDetails) {
    return this.http.patch<HostableVideo>(`${this.baseUrl}hosting/videos/${videoId}`, videoDetails);
  }

  //
  // Delete/Archive video clip
  //
  deleteVideo(id: number) {
    return this.http.delete<void>(this.baseUrl + 'hosting/videos/' + id);
  }

  rerender(id: number) {
    return this.http.post<HostableVideo>(this.baseUrl + 'hosting/videos/' + id + '/rerender', {});
  }

  render(id: number) {
    return this.http.post<HostableVideo>(this.baseUrl + 'hosting/videos/' + id + '/render', {});
  }

  cancel(id: number) {
    return this.http.post<void>(this.baseUrl + 'hosting/videos/' + id + '/cancel', {});
  }

  getSignedUrl(id: number, forceDownload: boolean) {
    const params = new HttpParams().set('forceDownload', forceDownload);
    return this.http.get<{ signedUrl: string }>(this.baseUrl + 'hosting/videos/' + id + '/source/signed-url', {
      params,
    });
  }

  checkVideoSlugExist(id: number, slug: string) {
    return this.http.get<{ slugExist: boolean }>(`${this.baseUrl}hosting/videos/${id}/slug-exist/${slug}`);
  }

  //
  // Get all hostable videos for an account
  //
  getAllHostedVideos(pageNumber: number, pageSize: number, sort?: SortOption, filters?: HostableListFilters) {
    let params = new HttpParams().set('page', pageNumber.toString()).set('perPage', pageSize.toString());

    if (sort) {
      params = params.set('orderDir', sort.direction.toLowerCase()).set('orderField', sort.field);
    }

    if (filters) {
      Object.entries(filters).forEach(([k, v]) => (params = params.set(k, v)));
    }

    return this.http.get<{ count: number; rows: HostableVideo[] }>(`${this.baseUrl}hosting/videos`, { params });
  }

  countAllHostedVideos(filters?: HostableCountFilters) {
    let params = new HttpParams();

    if (filters) {
      Object.entries(filters).forEach(([k, v]) => (params = params.set(k, v)));
    }

    return this.http.get<{ count: number }>(`${this.baseUrl}hosting/videos/count`, { params });
  }

  //
  // Get All hostable videos by given Hub id
  //
  getAllHostedVideos4Hub(hubId: number, pageNumber: number, pageSize: number, sort?: SortOption) {
    let params = new HttpParams().set('page', pageNumber.toString()).set('perPage', pageSize.toString());
    if (sort) params = params.set('orderDir', sort.direction.toLowerCase()).set('orderField', sort.field);

    return this.http.get<{ count: number; rows: HostableVideo[] }>(`${this.baseUrl}hosting/hubs/${hubId}/videos`, {
      params,
    });
  }

  //
  // Get single video by id
  //
  getVideoById(id: number) {
    return this.http.get<HostableVideo>(`${this.baseUrl}hosting/videos/${id}`);
  }

  getVideoByToken(videoToken: string): Observable<HostableVideo> {
    return this.http.get<HostableVideo>(`${this.baseUrl}hosting/videos/${videoToken}/details`);
  }

  //
  // Get single video embed details by id
  //
  getVideoEmbedById(id: number) {
    return this.http.get<HostableVideoEmbedApiResp>(`${this.baseUrl}hosting/videos/${id}/embed`);
  }

  //
  // Get video object by parent id and parent type
  //
  getVideoByParentIdType(id: number, type: HostableTypes) {
    return this.http.get<HostableVideo>(this.baseUrl + 'hosting/videos/parent/' + id + '/' + type);
  }

  //
  // Get paren object by video id
  //
  getParentObjectByHostableId(id: number) {
    return this.http.get<HostingParentObject>(this.baseUrl + 'hosting/videos/' + id + '/source');
  }

  //
  // Get Analytics details on video
  //
  getVideoViewsCounter(id: number): Observable<HostingVideoViewsCounter> {
    return this.http.get<HostingVideoViewsCounter>(this.baseUrl + 'hosting/analytics/video/playback/' + id);
  }

  // ----------------------- V I D E O   H U B S -----------------------

  //
  // Create a New Hub
  //
  createHub(title: string, permission: HostablePermissionTypes, slug: string) {
    return this.http.post<HostingHub>(`${this.baseUrl}hosting/hubs`, { title, permission, slug });
  }

  //
  // Get Hub details
  //
  getHub(id: number) {
    return this.http.get<HostingHub>(`${this.baseUrl}hosting/hubs/${id}`);
  }

  //
  // Delete a Hub
  //
  deleteHub(id: number) {
    return this.http.delete<void>(`${this.baseUrl}hosting/hubs/${id}`);
  }

  //
  // Put Hub Details
  //
  updateHub(id: number, hubDetails: UpdateHubDetails) {
    return this.http.patch<HostingHub>(`${this.baseUrl}hosting/hubs/${id}`, {
      title: hubDetails.title,
      permission: hubDetails.permission,
      password: hubDetails.password,
      slug: hubDetails.slug,
    });
  }

  updateHubPassword(id: number, password: string) {
    return this.http.patch<HostingHub>(`${this.baseUrl}hosting/hubs/${id}/password`, { password });
  }

  checkHubSlugExist(id: number, slug: string) {
    return this.http.get<{ slugExist: boolean }>(`${this.baseUrl}hosting/hubs/${id}/slug-exist/${slug}`);
  }

  //
  // get all Hubs for the account
  //
  getHubs(dto: HubVideoListQueryDto) {
    let params = new HttpParams().set('page', dto.page.toString()).set('perPage', dto.perPage);

    if (dto.query) {
      params = params.set('query', dto.query);
    }
    if (typeof dto.isOwner === 'boolean') {
      params = params.set('isOwner', dto.isOwner.toString());
    }

    return this.http.get<{ count: number; rows: HostingHub[] }>(`${this.baseUrl}hosting/hubs`, { params });
  }

  //
  // Get Video Count for given Hub
  //
  getVideoCount4Hub(hubId: number) {
    return this.http
      .get<{ count: number }>(`${this.baseUrl}hosting/hubs/${hubId}/videos/count`)
      .pipe(map(({ count }) => count));
  }

  //
  // Links one video to one Hub
  //
  link2Hub(videoId: number, hubId: number): Observable<HostingHub> {
    return this.http.post<HostingHub>(`${this.baseUrl}hosting/hubs/${hubId}/video`, { id: videoId });
  }

  //
  // UnLinks one video to one Hub
  //
  unlinkFromHub(videoId: number, hubId: number) {
    return this.http.delete<HostingHub>(`${this.baseUrl}hosting/hubs/${hubId}/video/${videoId}`);
  }

  //
  // Get all Hubs linked to the video
  //
  getHubs4video(videoid: number) {
    return this.http.get<HostingHub[]>(`${this.baseUrl}hosting/hubs/video/${videoid}`);
  }

  addCaption(hostableId: number, body: CreateHostingCaption) {
    return this.http.post<HostingCaption>(`${this.baseUrl}hosting/videos/${hostableId}/captions`, {
      ...body,
    });
  }

  updateCaption(hostableId: number, captionId: number, body: UpdateHostingCaption) {
    return this.http.put<HostingCaption>(`${this.baseUrl}hosting/videos/${hostableId}/captions/${captionId}`, {
      ...body,
    });
  }

  deleteCaption(hostableId: number, captionId: number) {
    return this.http.delete<void>(`${this.baseUrl}hosting/videos/${hostableId}/captions/${captionId}`);
  }

  getCaptions(hostableId: number) {
    return this.http.get<HostingCaption[]>(`${this.baseUrl}hosting/videos/${hostableId}/captions`);
  }

  fetchAutoGeneratedCaptions(hostableId: number): Observable<Omit<HostingVideoTranscript, 'summary' | 'chapters'>> {
    return this.http.get<HostingVideoTranscript>(`${this.baseUrl}hosting/videos/${hostableId}/captions/auto-generate`);
  }

  autoGenerateCaptions(hostableId: number): Observable<Omit<HostingVideoTranscript, 'summary' | 'chapters'>> {
    return this.http.post<HostingVideoTranscript>(
      `${this.baseUrl}hosting/videos/${hostableId}/captions/auto-generate`,
      {}
    );
  }

  translateCaptions(hostableId: number, targetLanguageCodes: string[]): Observable<HostingCaptionsTranslation> {
    return this.http.post<HostingCaptionsTranslation>(
      `${this.baseUrl}hosting/videos/${hostableId}/captions/translate`,
      { targetLanguageCodes }
    );
  }

  fetchCaptionTranslationStatus(hostableId: number): Observable<HostingCaptionsTranslation> {
    return this.http.get<HostingCaptionsTranslation>(`${this.baseUrl}hosting/videos/${hostableId}/captions/translate`);
  }

  getCaptionContent(hostableId: number, captionId: number): Observable<string> {
    return this.http.get<string>(`${this.baseUrl}hosting/videos/${hostableId}/captions/${captionId}/content`, {
      responseType: 'text' as 'json',
    });
  }

  createCustomThumbnail(hostableId: number, body: CreateHostingThumbail) {
    return this.http.post<HostingThumbnail>(`${this.baseUrl}hosting/videos/${hostableId}/thumbnail`, body);
  }

  getCustomThumbnail(hostableId: number) {
    return this.http.get<HostingThumbnail>(`${this.baseUrl}hosting/videos/${hostableId}/thumbnail`);
  }

  deleteCustomThumbnail(hostableId: number) {
    return this.http.delete<void>(`${this.baseUrl}hosting/videos/${hostableId}/thumbnail`);
  }

  createCta(hostableId: number, body: CreateHostingCta) {
    return this.http.post<HostingCta>(`${this.baseUrl}hosting/videos/${hostableId}/cta`, body);
  }

  updateCta(hostableId: number, body: UpdateHostingCta) {
    return this.http.put<HostingCta>(`${this.baseUrl}hosting/videos/${hostableId}/cta`, body);
  }

  getCta(hostableId: number) {
    return this.http.get<HostingCta>(`${this.baseUrl}hosting/videos/${hostableId}/cta`);
  }

  deleteCta(hostableId: number) {
    return this.http.delete<void>(`${this.baseUrl}hosting/videos/${hostableId}/cta`);
  }

  updateEmbedVideo(hostableId: number, enabled: boolean): Observable<HostingEmbedDto> {
    return this.http.patch<HostingEmbedDto>(`${this.baseUrl}hosting/videos/${hostableId}/embed`, { enabled });
  }

  fetchChapters(videoId: number): Observable<HostingVideoChapter[]> {
    return this.http.get<HostingVideoChapter[]>(`${this.baseUrl}hosting/videos/${videoId}/video-chapter`);
  }

  createChapter(videoId: number, dto: CreateHostingVideoChapterDTO): Observable<HostingVideoChapter> {
    return this.http.post<HostingVideoChapter>(`${this.baseUrl}hosting/videos/${videoId}/video-chapter`, dto);
  }

  updateChapter(videoId: number, dto: UpdateHostingVideoChapterDTO): Observable<HostingVideoChapter> {
    return this.http.put<HostingVideoChapter>(`${this.baseUrl}hosting/videos/${videoId}/video-chapter/${dto.id}`, dto);
  }

  deleteChapter(videoId: number, chapterId: number): Observable<void> {
    return this.http.delete<void>(`${this.baseUrl}hosting/videos/${videoId}/video-chapter/${chapterId}`);
  }

  autoGenerateVideoChapters(videoId: number): Observable<Omit<HostingVideoTranscript, 'summary'>> {
    return this.http.post<HostingVideoTranscript>(
      `${this.baseUrl}hosting/videos/${videoId}/video-chapter/auto-generate`,
      {}
    );
  }

  fetchAutoGeneratedVideoChapters(videoId: number): Observable<Omit<HostingVideoTranscript, 'summary'>> {
    return this.http.get<HostingVideoTranscript>(
      `${this.baseUrl}hosting/videos/${videoId}/video-chapter/auto-generate`
    );
  }

  deleteAutoGeneratedVideoChapters(videoId: number): Observable<Omit<HostingVideoTranscript, 'summary'>> {
    return this.http.delete<HostingVideoTranscript>(
      `${this.baseUrl}hosting/videos/${videoId}/video-chapter/auto-generate`
    );
  }

  fetchAppearance(videoId: number): Observable<HostingVideoAppearance> {
    return this.http.get<HostingVideoAppearance>(`${this.baseUrl}hosting/videos/${videoId}/appearance`);
  }

  createAppearance(videoId: number, dto: HostingVideoAppearance): Observable<HostingVideoAppearance> {
    return this.http.post<HostingVideoAppearance>(`${this.baseUrl}hosting/videos/${videoId}/appearance`, dto);
  }

  updateAppearance(videoId: number, dto: HostingVideoAppearance): Observable<HostingVideoAppearance> {
    return this.http.put<HostingVideoAppearance>(`${this.baseUrl}hosting/videos/${videoId}/appearance/`, dto);
  }

  autoGenerateVideoSummary(videoId: number): Observable<Omit<HostingVideoTranscript, 'chapters'>> {
    return this.http.post<HostingVideoTranscript>(`${this.baseUrl}hosting/videos/${videoId}/summary/auto-generate`, {});
  }

  fetchVideoSummary(videoId: number): Observable<Omit<HostingVideoTranscript, 'chapters'>> {
    return this.http.get<HostingVideoTranscript>(`${this.baseUrl}hosting/videos/${videoId}/summary/auto-generate`);
  }

  deleteVideoSummary(videoId: number): Observable<Omit<HostingVideoTranscript, 'chapters'>> {
    return this.http.delete<HostingVideoTranscript>(`${this.baseUrl}hosting/videos/${videoId}/summary/auto-generate`);
  }
}
