export enum CREATOR_LOG_FIELDS {
  JOB_ID = 'job_id',
  CREATOR_SCHEMA = 'creator.schema',
  CREATOR_BUILDER_ERRORS = 'creator.builder_errors',
  CREATOR_SCHEMA_ENTITY_ID = 'creator.schema_entity_id',
  CREATOR_SCHEMA_ENTITY_TYPE = 'creator.schema_entity_type',
  CREATOR_SCHEMA_ERRORS = 'creator.schema_errors',
  CREATOR_PROJECT_ID = 'creator.project_id',
  CREATOR_PROJECT_ASSET_ID = 'creator.project_asset_id',
  CREATOR_PROJECT_ASSET_FILE_ID = 'creator.project_asset_file_id',
  CREATOR_WORKFLOW_TYPE = 'creator.workflow_type',
  CREATOR_WORKFLOW_ID = 'creator.workflow_id',
  CREATOR_VIDEO_DURATION = 'creator.video_duration',
  CREATOR_RENDER_TIME = 'creator.render_time',
  CREATOR_RESOLUTION = 'creator.resolution',
  CREATOR_TIMINGS = 'creator.timing',
  CREATOR_CLIENT_VERSION = 'creator.client_version',
  CREATOR_TRANSCODER_FILES = 'creator.transcoder_files',
  CREATOR_TRANSCODER_FILES_ASSETS = 'creator.transcoder_files_assets',
}
