import { Injectable } from '@angular/core';
import { commonenv } from '../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { AutomationConnectionType } from '@openreel/common/automations';
import { ConnectionEntity } from './connections.interface';


@Injectable({
  providedIn: 'root',
})
export class ConnectionService {
  public urls = {
    list: commonenv.automationsApiUrl + 'connections',
    delete: (connectionId: number) => `${commonenv.automationsApiUrl}connections/${connectionId}`,
  };

  constructor(public httpClient: HttpClient) {}

  getConnections(type?: AutomationConnectionType, includeConnectionId?: number) {
    const params = new URLSearchParams();

    if (type) {
      params.set('type', type);
    }
    if (includeConnectionId) {
      params.set('includeConnectionId', includeConnectionId.toString());
    }

    return this.httpClient.get<ConnectionEntity[]>(this.urls.list + '?' + params.toString());
  }

  deleteConnection(connectionId: number) {
    return this.httpClient.delete(this.urls.delete(connectionId));
  }
}
