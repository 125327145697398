import { Injectable, OnDestroy } from '@angular/core';
import { commonenv } from '../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { ROUTE_OAUTH_CONNECTIONS } from '@openreel/frontend/common';
import { BaseOauthService } from './base-oauth.service';
import { AutomationTokenData, ConnectionAuthEntity } from '@openreel/common/automations';
import { SubscriptionCreateRequestEventTypeEnum } from '@hubspot/api-client/lib/codegen/webhooks';
import * as uuid from 'uuid';

@Injectable({
  providedIn: 'root',
})
export class HubspotService implements OnDestroy {
  public urls = {
    redirectUrl: (requestId: number) => commonenv.automationsApiUrl + 'connections/hubspot/auth' + `?requestId=${requestId}`,
    callbackUrl: commonenv.automationsApiUrl + 'connections/hubspot/auth/callback',
    tokens: (connectionId: number, eventType: SubscriptionCreateRequestEventTypeEnum) =>
      `${commonenv.automationsApiUrl}connections/hubspot/tokens?connection_id=${connectionId}&event_type=${eventType}`,
  };

  private activeChannels: BroadcastChannel[] = [];

  constructor(
    private readonly baseOauthService: BaseOauthService,
    private readonly httpClient: HttpClient,
  ) { }

  getRedirectUrl(requestId: number) {
    return this.httpClient.get<{ redirectUrl: string }>(this.urls.redirectUrl(requestId), {});
  }

  authCallback(code: string, state: string) {
    return this.httpClient.post<ConnectionAuthEntity>(this.urls.callbackUrl, { code, state });
  }

  login() {
    const requestId = uuid.v4();

    this.baseOauthService.openOauthWindow(`/${ROUTE_OAUTH_CONNECTIONS}/hubspot?requestId=${requestId}`, 'Hubspot Login');
    const channel = new BroadcastChannel(requestId);
    this.activeChannels.push(channel);

    return new Promise<ConnectionAuthEntity>((resolve) => {
      channel.onmessage = (event: MessageEvent<ConnectionAuthEntity>) => {
        resolve(event.data);
        channel.close();
        this.activeChannels = this.activeChannels.filter((c) => c.name !== channel.name);
      };
    });
  }

  getTokens(connectionId: number, eventType: SubscriptionCreateRequestEventTypeEnum) {
    return this.httpClient.get<AutomationTokenData[]>(this.urls.tokens(connectionId, eventType));
  }

  ngOnDestroy() {
    this.activeChannels.forEach((channel) => channel.close());
    this.activeChannels = [];
  }
}
