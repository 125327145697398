import { Component, Inject } from '@angular/core';
import { TeleprompterScript, FormHolder, TelepromterApiService, IPopupComponent } from '@openreel/frontend/common';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { ErrorStateMatcher } from '@angular/material/core';
import { FormGroup, FormControl } from 'ngx-strongly-typed-forms';
import { FormGroupDirective, NgForm, Validators, UntypedFormControl } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { TeleprompterScriptProgressStatus } from 'libs/frontend/common/src/interfaces';

export interface TeleprompterScriptEditPopupData {
  script?: TeleprompterScript;
  projectId: number;
}

interface TeleprompterScriptForm {
  title: string;
  content: string;
  progress_status: TeleprompterScriptProgressStatus;
}

/** Error when invalid control is dirty, touched, or submitted. */
export class MyErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: UntypedFormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    const isSubmitted = form && form.submitted;
    return !!(control && control.invalid && isSubmitted);
  }
}

@Component({
  selector: 'openreel-teleprompter-script-edit-popup',
  templateUrl: './teleprompter-script-edit-popup.component.html',
  styleUrls: ['./teleprompter-script-edit-popup.component.scss'],
})
export class TeleprompterScriptEditPopupComponent
  extends FormHolder<TeleprompterScriptForm>
  implements IPopupComponent
{
  templateScripts: TeleprompterScript[] = [];
  mode: 'edit' | 'create';
  status: TeleprompterScriptProgressStatus.Ready | TeleprompterScriptProgressStatus.NeedsWork
  matcher = new MyErrorStateMatcher();

  constructor(
    @Inject(MAT_DIALOG_DATA) 
    public data: TeleprompterScriptEditPopupData,
    public dialogRef: MatDialogRef<TeleprompterScriptEditPopupComponent>,
    private api: TelepromterApiService,
    private toastr: ToastrService
  ) {
    super();
    this.status = this.data?.script?.progress_status || TeleprompterScriptProgressStatus.Ready;
    this.setupForm(data.script || { progress_status: this.status });
    
    this.isPopup = true;
    this.isLightThemed = true;
  }
  isPopup: true;
  isLightThemed: boolean;

  async doSubmit(): Promise<void> {
    const newData = {
      ...this.form.value,
      scroll_speed: 1,
      ts_fontsize: 15,
    };
    if (this.mode === 'create') {
      try {
        await this.api.createScript({
          ...newData,
          project_id: this.data.projectId,
        });
        this.toastr.success('Script created successfully', 'Success!');
      } catch (error) {
        this.toastr.error(error.message, 'Error!');
      }
    } else {
      try {
        await this.api.editScript(this.data.script.ovra_teleprompter_script_id, newData);
        this.toastr.success('Script updated successfully', 'Success!');
      } catch (error) {
        this.toastr.error(error.message, 'Error!');
      }
    }
    this.dialogRef.close();
  }
  updateTextContent(content: string) {
    this.form.controls.content.setValue(content);
  }

  async toggleStatus(script): Promise<void> {

    if (script === 'ready'){
      this.status = TeleprompterScriptProgressStatus.NeedsWork;
      this.form = new FormGroup<TeleprompterScriptForm>({
        title: new FormControl(this.data?.script?.title || this.form.value.title || '', Validators.required),
        content: new FormControl(this.data?.script?.content || this.form.value.content || '', Validators.required),
        progress_status: new FormControl(TeleprompterScriptProgressStatus.NeedsWork, Validators.required),
      });
    } else {
      this.status = TeleprompterScriptProgressStatus.Ready;
      this.form = new FormGroup<TeleprompterScriptForm>({
        title: new FormControl(this.data?.script?.title || this.form.value.title || '', Validators.required),
        content: new FormControl(this.data?.script?.content || this.form.value.content || '', Validators.required),
        progress_status: new FormControl(TeleprompterScriptProgressStatus.Ready, Validators.required),
      });
    }
    
    
  }

  async setupForm(script: Partial<TeleprompterScript>) {
    this.mode = script.ovra_teleprompter_script_id ? 'edit' : 'create';
    this.form = new FormGroup<TeleprompterScriptForm>({
      title: new FormControl(script.title, Validators.required),
      content: new FormControl(script.content, Validators.required),
      progress_status: new FormControl(script.progress_status, Validators.required),
    });
  }

  onDialogCloseClicked() {
    this.dialogRef.close();
  }
}
