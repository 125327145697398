import { cloneDeep } from 'lodash';
import { SectionLayer, TemplateSchemaTransformation } from '../../../interfaces';
import { TemplateWorkflowDataDto_1_9 } from './schema_after';
import { TemplateWorkflowDataDto_1_8 } from '../template_transformation_1_8/schema_after';

export const TemplateSchemaTransformation_1_9: TemplateSchemaTransformation = {
  version: '1.9',

  run(oldSchema: TemplateWorkflowDataDto_1_8.WorkflowDataDto): TemplateWorkflowDataDto_1_9.WorkflowDataDto {
    const newSchema = cloneDeep(oldSchema) as unknown as TemplateWorkflowDataDto_1_9.WorkflowDataDto;

    const mainTimeline = newSchema.timelines.find((t) => t.type === 'main');
    const sectionIdsFromTimeline = mainTimeline.layers.map((l) => (l as SectionLayer).sectionId);

    for (const section of Object.keys(newSchema.sections)) {
      if (!sectionIdsFromTimeline.includes(section)) {
        delete newSchema.sections[section];
      }
    }

    return newSchema;
  },
};
