import { getLayerFromId } from '../helpers/timelines.helpers';
import { Bounds, LayerIdEvent } from '../interfaces';
import { WorkflowBaseBuilder } from './workflow-base.builder';

export interface UpdateLottieTextEvent extends LayerIdEvent {
  fieldId: string;
  text: string;
  bounds?: Bounds;
}

export class UpdateLottieTextCommand extends WorkflowBaseBuilder<UpdateLottieTextEvent> {
  run({ layerId, fieldId, text, bounds }: UpdateLottieTextEvent) {
    const layerData = getLayerFromId(layerId, this.source);
    if (!layerData) {
      return this.error(`No layer found for id: ${layerId}`);
    }

    const { layer } = layerData;
    if (layer.type !== 'lottie') {
      return this.error('Layer type is not lottie.');
    }

    const layerFieldData = layer.data[fieldId];
    const styleId = layerFieldData.styleId;

    this.applyLayerChanges(layer, {
      values: {
        [fieldId]: {
          type: 'text',
          value: text,
          styleId,
        },
      },
      assetChanges: {},
      styleChanges: {},
    });

    if (layer.isTextBox) {
      this.updateTextLayerBounds(layer, bounds);
    }

    return this.ok();
  }
}
