import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  HostBinding,
  InjectionToken,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
  inject,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { AssetId, AssetType, AssetsFileProviderType } from '@openreel/creator/common';
import { merge, noop } from 'lodash-es';
import { Observable, Subject, combineLatest, of } from 'rxjs';
import { distinctUntilChanged, map, skipWhile, switchMap, takeUntil } from 'rxjs/operators';
import { AlertService } from './../openreel-alert/openreel-alert.service';
import { FileInfo, FileUploadStatus, OPENREEL_UPLOAD_MANAGER, OpenreelUploadManager } from './openreel-upload-manager';
import { FileService } from '@openreel/frontend/common';
import { ToastrService } from 'ngx-toastr';

export const ACCEPTED_FILE_EXTENSIONS: AcceptedFileExtension = {
  image: {
    extensions: '.png,.jpg,.jpeg,.webp',
    type: 'image',
  },
  video: {
    extensions: '.mp4,.webm,.mov',
    type: 'video',
  },
  audio: {
    extensions: '.mp3',
    type: 'audio',
  },
  document: {
    extensions: '.vtt,.srt,.json',
    type: 'document',
  },
  csv: {
    extensions: '.csv',
    type: 'document',
  },
  caption: {
    extensions: '.vtt,.srt',
    type: 'document',
  },
  font: {
    extensions: '.ttf,.otf',
    type: 'font',
  },
  presentation: {
    extensions: '.pdf',
    type: 'document',
  },
};

interface AcceptedFileExtension {
  [key: string]: {
    extensions: string;
    type: UploadTypes;
  };
}

interface S3Credentials {
  credentials: {
    accessKeyId: string;
    secretAccessKey: string;
    sessionToken: string;
  };
  region: string;
  bucket: string;
  key: string;
}

export type UploadTypes = 'image' | 'video' | 'audio' | 'document' | 'font' | 'presentation';

export interface OpenreelUploaderExtraOptions {
  isTeamWorkspaceUpload?: boolean;
}
export interface FileMetadata {
  file: File;
  fileName: string;
  fileResolution?: { width: number; height: number };
  mediaDurationMs?: number;
}

export type UploadCredentials = string | S3Credentials;

export const UPLOADER_SERVICE_TOKEN = new InjectionToken<UploaderBaseService<UploadCredentials>>(
  'UPLOADER SERVICE TOKEN'
);

export interface GetUploadCredentialsOptions {
  uid?: string;
  type: UploadTypes;
  extension: string;
  name?: string;
  fileSize?: number;
  fileResolution?: { width: number; height: number };
  duration?: number;
  feature?: string;
}

export abstract class UploaderBaseService<T extends UploadCredentials> {
  abstract getUploadCredentials(
    options: GetUploadCredentialsOptions
  ): Observable<{ id: string | number; credential: T }>;

  abstract upload(credentials: T, file: File, id?: AssetId): Observable<{ loaded: number; total: number }>;
}

export const VALIDATOR_SERVICE_TOKEN = new InjectionToken<FileValidatorBaseService>('FILE VALIDATOR SERVICE TOKEN');

export type FileValidationError = null | string;

export abstract class FileValidatorBaseService {
  abstract validate(file: File): FileValidationError | Observable<FileValidationError>;
}

export interface UploaderOptions {
  showBackgroundColor?: boolean;
  showRemoveAction?: boolean;
  clearAfterUpload?: boolean;
  showAlertOnError?: boolean;
  showAlertOnValidationError?: boolean;
  multiple?: boolean;
  isImageClickable?: boolean;
  forceShowFilePicker?: boolean; // Skip internal state changes subscription and show file picker
}

const DEFAULT_OPTIONS: UploaderOptions = {
  showBackgroundColor: true,
  showRemoveAction: true,
  showAlertOnError: true,
  clearAfterUpload: false,
  showAlertOnValidationError: true,
  multiple: false,
  isImageClickable: false,
  forceShowFilePicker: false,
};

function clipSize(mbs: number) {
  if (mbs >= 1024) {
    return Math.round(mbs / 1024) + 'GB';
  }
  if (mbs < 1) {
    return Math.round(mbs * 1024) + 'KB';
  }
  return Math.round(mbs) + 'MB';
}

export class SelectingFileEvent {
  preventDefault = false;
}

export function injectOpenreelUploadManager(): OpenreelUploadManager {
  const uploadManager = inject(OPENREEL_UPLOAD_MANAGER, { optional: true });

  if (uploadManager) {
    return uploadManager;
  }

  const uploaderService = inject(UPLOADER_SERVICE_TOKEN);
  const fileValidatorService = inject(VALIDATOR_SERVICE_TOKEN, { optional: true });
  const fileService = inject(FileService);

  return new OpenreelUploadManager(uploaderService, fileValidatorService, fileService);
}

@Component({
  selector: 'openreel-uploader',
  templateUrl: './openreel-uploader.component.html',
  styleUrls: ['./openreel-uploader.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: OpenreelUploaderComponent,
    },
  ],
})
export class OpenreelUploaderComponent implements ControlValueAccessor, OnInit, OnDestroy {
  @Input() uploadCaption: string;
  @Input() assetType: AssetType;
  @Input() type: UploadTypes;
  @Input() types: UploadTypes[];
  @Input() selectedSourceProvider?: AssetsFileProviderType;

  @Input() allowDragAndDrop = false;
  @Input() set disabled(value: boolean) {
    this.setDisabledState(value);
  }

  @Input() fullSize = false;
  @Input() uploadedClasses = '';
  @Input() options: UploaderOptions;
  @Input() showCancelBtn = false;
  @Input() resetUploader: Observable<boolean>;
  @Input() extra: OpenreelUploaderExtraOptions = {};
  @Input() set validatePdf(value: boolean) {
    if (value) {
      this.uploadManager.toValidatePdf();
    }
  }

  @Input() @HostBinding('class.dark') darkBackground = false;
  @HostBinding('class.dragging') isDraggingOver = false;

  @Output() started = new EventEmitter<AssetId>();
  @Output() metadata = new EventEmitter<FileMetadata>();
  @Output() upload = new EventEmitter<AssetId>();
  @Output() failed = new EventEmitter<string>();
  @Output() remove = new EventEmitter();
  @Output() uploadCancel = new EventEmitter();
  @Output() fileValidationStarted = new EventEmitter();
  @Output() fileValidationEnded = new EventEmitter<boolean>();
  @Output() filesSelected = new EventEmitter<FileInfo[]>();
  @Output() selectingFile = new EventEmitter<SelectingFileEvent>();

  @ViewChild('fileInput') fileInput: ElementRef<HTMLInputElement>;
  @ViewChild('video') video: ElementRef<HTMLVideoElement>;

  private readonly unsub$ = new Subject<void>();
  private readonly alertService = inject(AlertService);
  private readonly cd = inject(ChangeDetectorRef);
  private readonly uploadManager = injectOpenreelUploadManager();

  readonly isUploaded$ = this.uploadManager.status$.pipe(map((status) => status === FileUploadStatus.UploadCompleted));
  readonly isSourceIdAssigned$ = this.uploadManager.status$.pipe(
    map((status) => status === FileUploadStatus.SourceIdAssigned)
  );
  readonly sourceId$ = combineLatest([this.isUploaded$, this.isSourceIdAssigned$]).pipe(
    switchMap(([isUploaded, isSourceIdAssigned]) =>
      isUploaded || isSourceIdAssigned ? this.uploadManager.sourceId$ : of(null)
    )
  );

  readonly fileName$ = this.uploadManager.file$.pipe(map((file) => file?.name));
  readonly fileSize$ = this.uploadManager.file$.pipe(
    map((file) => (file ? clipSize(file.size / (1024 * 1024)) : null))
  );

  parsedOptions: UploaderOptions;

  sourceId: AssetId;
  isDisabled = false;

  isUploading = false;
  uploadProgress = 0;
  fileName: string | null = null;

  propagateOnChanged: (sourceId: AssetId) => void = noop;
  propagateOnTouched = noop;

  get acceptedFileExtensions() {
    if (this.types?.length) {
      return this.types.map((type) => ACCEPTED_FILE_EXTENSIONS[type].extensions).join(',');
    }
    return ACCEPTED_FILE_EXTENSIONS[this.type].extensions;
  }

  get fileType(): UploadTypes {
    if (this.type) {
      return ACCEPTED_FILE_EXTENSIONS[this.type].type;
    } else if (this.assetType) {
      let type: UploadTypes;
      switch (this.assetType) {
        case 'image':
          type = 'image';
          break;
        case 'clip':
          type = 'video';
          break;
        case 'audio':
          type = 'audio';
          break;
        case 'json':
          type = 'document';
          break;
      }

      return ACCEPTED_FILE_EXTENSIONS[type].type;
    } else {
      throw new Error('Type or assetType must be provided in order to be able to preview uploaded asset.');
    }
  }

  constructor(private readonly toastr: ToastrService) {}

  ngOnInit() {
    this.parsedOptions = merge({}, DEFAULT_OPTIONS, this.options);
    this.resetUploader?.pipe(takeUntil(this.unsub$)).subscribe((value) => {
      if (value) {
        this.uploadManager.resetCurrentFileInfo();
      }
    });

    this.cd.markForCheck();
    this._subscribeProcessingFileStateChanges();
  }

  ngOnDestroy(): void {
    this.unsub$.next();
    this.unsub$.complete();
  }

  writeValue(sourceId: AssetId) {
    this.sourceId = sourceId;
    this.uploadManager.setSourceId(sourceId);
    this.cd.markForCheck();
  }

  registerOnChange(fn: (sourceId: AssetId) => void) {
    this.propagateOnChanged = fn;
  }

  registerOnTouched(fn: () => void) {
    this.propagateOnTouched = fn;
  }

  selectFile() {
    this.fileInput.nativeElement.click();
  }

  onSelectImage() {
    if (!this.options.isImageClickable) {
      return;
    }

    this.onSelectFile();
  }

  onSelectFile() {
    const event = new SelectingFileEvent();
    this.selectingFile.emit(event);

    if (!event.preventDefault) {
      this.selectFile();
    }
  }

  removeFile() {
    this.propagateOnTouched();

    this.clearFile();

    this.remove.emit();
  }

  cancelUpload() {
    this.uploadManager.cancelCurrentUpload();
    this.uploadCancel.emit();
  }

  onDragOver(event: Event) {
    event.preventDefault();
  }

  onDragEnter(event: Event) {
    event.preventDefault();

    if (!this.allowDragAndDrop) {
      return;
    }

    this.isDraggingOver = true;
    this.cd.markForCheck();
  }

  onDragLeave(event: Event) {
    event.preventDefault();

    if (!this.allowDragAndDrop) {
      return;
    }

    this.isDraggingOver = false;
    this.cd.markForCheck();
  }

  onDrop(event: DragEvent) {
    event.preventDefault();

    if (!this.allowDragAndDrop || !event.dataTransfer.files.length) {
      return;
    }

    this._addFilesAndStartUpload(event.dataTransfer.files);
  }

  onFileDialogChange($event: Event) {
    $event.stopPropagation();

    this.propagateOnTouched();

    const target = $event.target as HTMLInputElement;
    if (target.files.length === 0) {
      return;
    }

    this._addFilesAndStartUpload(target.files);
  }

  private _addFilesAndStartUpload(fileList: FileList) {
    const files: FileInfo[] = [];

    if (!this.parsedOptions.multiple) {
      const filteredFiles = Array.from(fileList).filter((file) =>
        this.acceptedFileExtensions.split(',').some((ext) => file.name.endsWith(ext))
      );

      if (!filteredFiles.length) {
        this.toastr.error('Invalid file type');
        return;
      }

      let type = this.type;

      if (this.types?.length) {
        for (let i = 0; i < this.types.length; i++) {
          if (this.types[i] === 'image' && filteredFiles[0].type.includes('image')) {
            type = 'image';
            break;
          } else if (this.types[i] === 'video' && filteredFiles[0].type.includes('video')) {
            type = 'video';
            break;
          }
        }
      }

      this.type = type;

      files.push({ file: filteredFiles[0], type, extra: this.extra });
    } else {
      for (let i = 0; i < fileList.length; i++)
        files.push({ file: fileList.item(i), type: this.type, extra: this.extra });
    }

    this.filesSelected.emit(files);
    this.uploadManager.addFiles(files);
    this.uploadManager.startUpload();
  }

  setDisabledState(isDisabled: boolean) {
    this.isDisabled = isDisabled;
    this.cd.markForCheck();
  }

  private clearFile() {
    this.fileInput.nativeElement.files = new DataTransfer().files;
    this.uploadManager.resetCurrentFileInfo();
  }

  private resetNativeControlValue(): void {
    this.fileInput.nativeElement.value = null;
  }

  private _subscribeProcessingFileStateChanges() {
    this.uploadManager.isUploadInProgress$.pipe(takeUntil(this.unsub$)).subscribe((isUploading) => {
      this.isUploading = isUploading;
      this.cd.markForCheck();
    });

    this.uploadManager.progress$.pipe(takeUntil(this.unsub$)).subscribe((progress) => {
      this.uploadProgress = progress;
      this.cd.markForCheck();
    });

    this.fileName$.pipe(takeUntil(this.unsub$)).subscribe((fileName) => (this.fileName = fileName));

    this.sourceId$
      .pipe(
        skipWhile((value) => !value || value.sourceId === null),
        distinctUntilChanged((prev, curr) => prev?.sourceId === curr?.sourceId),
        takeUntil(this.unsub$)
      )
      .subscribe((value) => {
        this.sourceId = value?.sourceId ?? null;
        this.cd.markForCheck();

        if (value?.status !== FileUploadStatus.SourceIdAssigned) {
          this.propagateOnChanged(this.sourceId);
        }
      });

    this.uploadManager.processingFileState$.pipe(takeUntil(this.unsub$)).subscribe((fileState) => {
      const { status, file, sourceId, error, metadata } = fileState;

      switch (status) {
        case FileUploadStatus.ValidationStarted:
          this.fileValidationStarted.emit();
          break;
        case FileUploadStatus.ValidationCompleted:
          this.fileValidationEnded.emit(true);
          break;
        case FileUploadStatus.ValidationFailed:
          if (this.parsedOptions.showAlertOnValidationError && !!error) {
            this.alertService.error(error, 'File not allowed');
          }
          this.resetNativeControlValue();
          this.fileValidationEnded.emit(false);
          break;
        case FileUploadStatus.MetadataReceived:
          this.metadata.emit({
            file,
            fileName: file?.name,
            fileResolution: metadata?.resolution,
            mediaDurationMs: metadata?.durationMs,
          });
          break;
        case FileUploadStatus.UploadStarted:
          this.started.emit(sourceId);
          break;
        case FileUploadStatus.UploadCompleted:
          this.upload.emit(sourceId);

          if (this.parsedOptions.clearAfterUpload) {
            this.clearFile();
          }
          break;
        case FileUploadStatus.UploadFailed:
          if (this.parsedOptions.showAlertOnError && !!error) {
            this.alertService.error(error);
          }
          this.resetNativeControlValue();
          this.failed.emit(error);
          break;
        default:
          break;
      }
    });
  }
}
